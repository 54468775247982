<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';
import Title from '@/Components/Title/Title.vue';

const route = inject('route');
const props = defineProps({
    showing: Boolean,
    shift: Object,
});

const emits = defineEmits(['update:showing']);

const closeForm = () => emits('update:showing', false);

const form = useForm({ shift_id: '' });

const sendForm = () => {
    form.shift_id = props.shift.id;

    form.post(route('location.splitted-shifts.store'), {
        onSuccess: closeForm,
    });
};
</script>

<template>
    <Modal :open="showing" @closing="closeForm">
        <template #default>
            <Title :title="$t('Split {model}', { model: $t('shift') })"> </Title>

            <p class="text-base text-blue">
                {{ $t(`To edit this shift it will need to be split into multiple shifts`) }}
            </p>
        </template>
        <template #button-bar>
            <Button danger @click="sendForm" :processing="form.processing" :recentSuccessful="form.recentSuccessful">
                {{ $t('Split {model}', { model: $t('shift') }) }}
            </Button>
            <Button orange @click="closeForm">
                {{ $t('Cancel') }}
            </Button>
        </template>
    </Modal>
</template>
